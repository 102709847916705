
<template>
    <div class="card card-body shadow-sm receipt-list">
        <validation-observer
        ref="form_create_entry"
        v-slot="{ passes, valid, validated }"
        >
        <app-basic-table
            ref="basic_table"
            :table-name="$t('users.user list')"
            :filters.sync="filters"
            :setting-columns="columns"
            :endpoint-list="ENDPOINT.RECEIPTS_LIST"
            @resetAllSearch="onResetAllSearch"
            @searched="onSearch"
        >
            <template v-slot:filters>
                <div class="row justify-content-center">
                    <div class="col-xl-4 col-lg-4 col-sm-4">
                        <div class="form-group row">
                            <div class="col-xl-12 col-lg-12 col-sm-12 mt-0">
                                <label class="align-self-center mb-0 mr-5">{{ filters.status_enabled.label }}</label>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-sm-12 mt-2">
                                <app-radio 
                                    v-for="(item, index) in RECEIPTS_CONSTANT.FILTER_STATUS"
                                    :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                                    :val="item.id" v-model="filters.status_enabled.value"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-8 col-lg-8 col-sm-8 gr-datetime">
                        <div class="row">
                            <div class="col-xl-5 col-lg-5 col-sm-5">
                                <div class="form-group row">
                                    <div class="col-xl-12 col-lg-12 col-sm-12 mt-0">
                                        <label class="align-self-center mb-0 mr-5">{{ filters.from_date_between.label }}</label>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-sm-12 mt-2">
                                        <app-date-range-picker
                                            :rules="`from_date_time_list_page:${filters.to_date_between.value}`"
                                            :name="filters.from_date_between.name"
                                            v-model="filters.from_date_between.value"
                                            format="YYYY-MM-DD H:mm:ss"
                                            :timePickerSeconds="true"
                                            @input="filters.from_date_between.value = $event"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-sm-2 text-center">
                                <span style="font-size: 30px; margin-top: 30px; display: inline-block">~</span>
                                </div>
                            <div class="col-xl-5 col-lg-5 col-sm-5">
                                <div class="form-group row">
                                    <div class="col-xl-12 col-lg-12 col-sm-12 mt-0">
                                        <label class="align-self-center mb-0 mr-5" style="opacity: 0">{{ filters.to_date_between.label }}</label>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-sm-12 mt-2">
                                        <app-date-range-picker
                                            :name="filters.to_date_between.name"
                                            v-model="filters.to_date_between.value"
                                            format="YYYY-MM-DD H:mm:ss"
                                            :timePickerSeconds="true"
                                            @input="filters.to_date_between.value = $event"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-xl-4 col-lg-4 col-md-4">
                        <div class="form-group">
                            <label>{{ filters.order_id.label }}</label>
                            <app-input 
                            :name="filters.order_id.name" 
                            input-style="normal"
                            v-model="filters.order_id.value"
                            />
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4">
                        <div class="form-group">
                            <label>{{ filters.customer_name.label }}</label>
                            <app-input 
                            :name="filters.customer_name.name" 
                            input-style="normal"
                            v-model="filters.customer_name.value"
                            />
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4">
                        <div class="form-group">
                        <label>{{ filters.apartment_id.label }}</label>
                        <app-select
                            :name="filters.apartment_id.value"
                            input-style="normal"
                            v-model="filters.apartment_id.value"
                            :options-data="meta.apartments"
                        />
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:body-cell-status_enabled="props">
                <td class="app-align-middle">
                    <p class=" app-table-p app-cell-tooltip mb-0">
                        {{getStatusName(props.row.status_enabled)}}
                    </p>
                </td>
            </template>

            <template v-slot:body-cell-apartment_id="props">
                <td class="app-align-middle">
                    <p :data-original-title="props.row.apartment_name" style="color: #007bff;" @click="handlerApartmentNameClick(props.row)"  class="apartment_name app-table-p app-cell-tooltip mb-0">
                        {{props.row.apartment_name}}
                    </p>
                </td>
            </template>

            <template v-slot:table-menu-right>
                <button @click="handleBtnCreateClick()" class="btn btn-warning">
                    {{ $t("common.create") }}
                </button>
            </template>
            <template v-slot:body-cell-edit="props">
                <td class="app-align-middle text-center app-p-none">
                <button
                    @click="handleBtnUpdateClick(props.row)"
                    class="btn btn-primary"
                >
                    {{ $t("common.edit") }}
                </button>
                </td>
            </template>
        </app-basic-table>
        </validation-observer>
    </div>
</template>
<script>
import AppBasicTable from "@components/_common/list/AppBasicTable";
import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
import {ROLES, BASIC_TABLE} from "@constants";
import {RECEIPTS_CONSTANT} from "../../../constants/receipts";
export default {
    name: "ReceiptsList",

    components: {
        "app-basic-table": AppBasicTable
    },

    data() {
        return {
            idCache: null,
            paramGetList: {},
            filters: {
                status_enabled: {
                    name: "status_enabled",
                    condition: "equal",
                    label: this.$t("common.status")
                },
                order_id: {
                    name: "order_id",
                    condition: "like",
                    label: this.$t("receipts.order_id"),
                },
                customer_name: {
                    name: "customer_name",
                    condition: "like",
                    label: this.$t("receipts.customer_name"),
                },
                apartment_id: {
                    name: "apartment_id",
                    condition: "equal",
                    label: this.$t("receipts.mansion"),
                },
                from_date_between: {
                    name: "from_date",
                    condition: "between",
                    label: this.$t("receipts.datetime_order")
                },
                to_date_between: {
                    name: "to_date",
                    condition: "between",
                    label: this.$t("receipts.datetime_order")
                },
            },
            columns: [
                {name: "id", label: this.$t("common.id"), sortable: true, textAlign: 'text-center'},
                {name: "status_enabled", label: this.$t("common.status"), sortable: true},
                {name: "apartment_id", label: this.$t("receipts.mansion"), sortable: true},
                {name: "receipt_number", label: this.$t("receipts.receipt_number"), sortable: true},
                {name: "received_from", label: this.$t("receipts.received_from"), sortable: true},
                {name: "order_id", label: this.$t("receipts.order_id"), sortable: true, textAlign: 'text-center'},
                {name: "date_order", label: this.$t("receipts.date_order"), sortable: true, textAlign: 'text-center'},
                {name: "hour_order", label: this.$t("receipts.hour_order"), textAlign: 'text-center'},
                {name: "customer_name", label: this.$t("receipts.customer_order"), sortable: true},
                {name: "edit", label: this.$t("common.edit")},
            ],
            meta : {
                orders: [],
                apartments: [],
            }
        }
    },

    watch : {
        "filters.apartment_id.value" : function(value) {
            this.getMetaData({apartment_id : value})
        },
        "filters.from_date_between.value" : async function(value) {
            await this.$refs.form_create_entry.validate()
        },
        "filters.to_date_between.value" : async function(value) {
            await this.$refs.form_create_entry.validate()
        },
    },

    mounted: function () {
       this.getMetaData({})
    },

    methods: {
        getMetaData(params) {
            this.$request.get(this.ENDPOINT.UI_HELPER_RECEIPTS, params).then(res => {
                this.meta = {
                    apartments: res.data.apartments,
                }
                this.meta.apartments.unshift({
                    id: "all",
                    name: this.$t('common.all'),
                })
            })
        },

        handleBtnCreateClick() {
            this.$router.push({name: this.ROUTES.ADMIN.RECEIPTS_CREATE})
        },

        handleBtnUpdateClick(entry) {
            localStorage.setItem('receipt_search_url', window.location.href);
            this.$router.push({name: this.ROUTES.ADMIN.RECEIPTS_EDIT, params: {id: entry.id}})
        },

        onResetAllSearch() {
            this.$router.push({
                name: this.ROUTES.ADMIN.RECEIPTS_LIST, query: {
                    'filters.status_enabled.equal': 'all',
                    'filters.apartment_id.equal' : 'all',
                    'filters.from_date.between' : moment().format('YYYY-MM-DD H:mm:ss'),
                    'filters.to_date.between' : moment().format('YYYY-MM-DD H:mm:ss'),
                }
            })
        },

        onSearch() {
            // this.getTourSelectName();
        },
        
        getStatusName(status) {
            let data = RECEIPTS_CONSTANT.STATUS.find(({id}) => id === status);
            return data.name
        },

        handlerApartmentNameClick(entry) {
            this.$router.push({name: this.ROUTES.ADMIN.APARTMENT_EDIT, params: {id: entry.apartment_id}})
        },

        getApartmentName(apartment_id) {
            let apartment_name = this.meta.big_category.find(({id}) => id === apartment_id);
            if (apartment_name == undefined) {
                return '';
            }
            return apartment_name.name;
        },

        getApartmentEditUrl(apartment_id) {
            let props = this.$router.resolve({
                name: this.ROUTES.ADMIN.APARTMENT_EDIT,
                params: { id: apartment_id },
            });
            return props.href;
        },
    },
}
</script>
